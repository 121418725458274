import { defineStore } from "pinia";
import { reactive, ref, nextTick } from "vue";
import { type LocationQuery, useRouter, useRoute } from "vue-router";
import { cloneDeep } from "lodash-es";
import { HttpCodes, urls } from "~/api/config";
import { useTransport } from "~/composables/useTransport";
import { useCategoriesStore } from "~/store/categories";
import { useStoresStore } from "~/store/stores";
import { useUserStore } from "~/store/user";
import type { IProduct } from "~/components/VProduct/VProduct.types";
import { Routes } from "~/utils/routes";

interface IGoodsProductImage {
  defaultSize: string;
  postfixUrl: string;
  prefixUrl: string;
}
interface IGoodsOffer {
  actionDate?: string; // 2023-04-04T23:59:59
  discountPercent?: number;
  isAction: boolean;
  oldPrice?: string; // 399.99
  price: string; // 299.99
  quantity?: number;
}

export interface IGoodsNutritionsFacts {
  groupName: string;
  parameters: Array<{
    name: string;
    value: string;
  }>;
}

export interface IGoodsProduct {
  categories: number[];
  code: string;
  grammar: number;
  id: string;
  image: IGoodsProductImage;
  name: string;
  isForAdults: boolean;
  offers: {
    0: IGoodsOffer;
  } & IGoodsOffer[];
  unitValue?: string;
  term?: string;
}
interface IDetailsGallery {
  object: {
    defaultSize: string;
    postfixUrl: string;
    prefixUrl: string;
  };
  type: string;
}

interface IParameters {
  name: string;
  value: string;
}
interface IDetailsGroupParameters {
  groupName: string;
  parameters: IParameters[];
}
export interface IGoodsProductByIDs extends IGoodsProduct {
  details?: {
    description: string;
    gallery: IDetailsGallery[];
    groupParameters: IDetailsGroupParameters[];
    nutritionsFacts: {
      groupName: string;
      parameters: IParameters[];
    };
    structure: string;
  };
}

export interface IGoodsProductFull extends IGoodsProduct {
  details: {
    description?: string;
    gallery?: Array<{
      object: IGoodsProductImage;
      type: "image/*";
    }>;
    groupParameters?: Array<{
      groupName: string;
      parameters: Array<{
        name: string;
        value: string;
      }>;
    }>;
    structure?: unknown;
    nutritionsFacts?: IGoodsNutritionsFacts;
  };
}

export type IGoodsFilterId =
  | "brands"
  | "vendors"
  | "countries"
  | "productTypes"
  | "priceRange";

export interface IGoodsFilterElement {
  count: number;
  name: string;
}

export interface IRangeFilterValue {
  min: number;
  max: number;
}

export type IFilterValuesList = Array<IGoodsFilterElement["name"]>;

type IGoodsFilterType = "range" | "grid";

interface IGoodsFilterBase {
  id: IGoodsFilterId;
  title: string;
  type: IGoodsFilterType;
}

export interface IGoodsGridFilter extends IGoodsFilterBase {
  elements: IGoodsFilterElement[];
  type: "grid";
}

export interface IGoodsRangeFilter extends IGoodsFilterBase {
  maxvalue: number;
  minvalue: number;
  step: number;
  unit: string;
  type: "range";
}

export type IGoodsFilter = IGoodsGridFilter | IGoodsRangeFilter;

export interface IGoodsGridRequestFilter {
  elements: IFilterValuesList;
  id: IGoodsFilterId;
}

export interface IGoodsRangeFilterValue {
  min: number;
  max: number;
}

export interface IGoodsRangeRequestFilter {
  id: IGoodsFilterId;
  range: IGoodsRangeFilterValue;
}

type IGoodsRequestFilter = IGoodsGridRequestFilter | IGoodsRangeRequestFilter;

export interface IGoodsProducts {
  items: IGoodsProduct[];
  pagination: {
    hasMore: boolean;
    page: number;
    size: number;
    totalCount: number;
    totalPages: number;
  };
  filters?: IGoodsRequestFilter[];
}

interface IGoodsStatus {
  products: "initial" | "pending" | "success" | "error";
  filteredProducts: "initial" | "pending" | "success" | "error";
  filters: "initial" | "pending" | "success" | "error";
}

export interface IGoodsPagination {
  number: number;
  size: number;
}

export interface IRequestGoodsParams {
  categoryIDs?: number[];
  pagination: IGoodsPagination;
  onlyDiscount?: boolean;
  includeForAdults?: boolean;
  sortBy?: string; // price | discount
  order?: string; // asc | desc;
  filters?: IGoodsRequestFilter[];
  storeCode?: string;
  term?: string;
}

// https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/goods/post_v1_goods_store
export enum ShopType {
  AtHouse = "1",
  Family = "2",
  Cosmetic = "3",
  Pharmacy = "4",
  Wholesale = "5",
  Extra = "6",
  Master = "7",
}
export interface IRequestGoodsByIDsParams {
  goodIDs: string[];
  shopType?: ShopType;
  storeCode?: string;
}

export interface IRequestGoodsAllParams extends IRequestGoodsParams {
  storeCodes: string[] | number[];
}

export const useGoodsStore = defineStore("goods", () => {
  const userStore = useUserStore();
  const storesStore = useStoresStore();
  const categoriesStore = useCategoriesStore();
  const route = useRoute();
  const router = useRouter();

  const filteredProducts = reactive<IGoodsProducts>({
    ...GOODS_DEFAULT_PRODUCTS,
  });
  const products = reactive<IGoodsProducts>({ ...GOODS_DEFAULT_PRODUCTS });
  const filters = ref<IGoodsFilter[]>([]);
  const sortedFilters = ref<IGoodsFilter[]>([]);
  const selectedFilters = ref<IGoodsRequestFilter[]>([]);
  const urlFilters = ref<IGoodsRequestFilter[]>([]);
  const selectedCategoryId = ref<number | null>(null);
  const currentCategoryId = ref<number | null>(null);

  function getCategoryDescendants(category: number[]) {
    const tree = categoriesStore.getChildrenById(
      category[0] ? String(category[0]) : "",
      "goods",
    );
    return [...tree.map((b) => Number(b.id))];
  }

  const status = reactive<IGoodsStatus>({
    products: "initial",
    filteredProducts: "initial",
    filters: "initial",
  });

  async function requestFilters(params: Partial<IRequestGoodsParams>) {
    status.filters = "pending";

    if (params.filters) {
      delete params.filters;
    }

    const { categoryIDs, storeCode, ...etc } = params;

    const props: Partial<IRequestGoodsAllParams> = {
      storeCodes: [storeCode || storesStore.currentStore.code],
      onlyDiscount: false,
      includeForAdults: userStore.loadAdultContent,
      ...etc,
    };

    if (categoryIDs?.length) {
      props.categoryIDs = getCategoryDescendants(categoryIDs);
    }

    const { data, error } = await useTransport<{
      filters: IGoodsFilter[];
      pagination: IGoodsProducts["pagination"];
    }>(urls.goods.filters, {
      method: "POST",
      body: props,
      watch: false,
    });

    if (data.value) {
      status.filters = "success";

      filters.value = [...data.value.filters];
      sortedFilters.value = [...data.value.filters];

      if (selectedFilters.value.length) {
        selectedFilters.value.forEach((filter) => {
          if ("elements" in filter) {
            sortGridFilterBySelection(filter.id, filter.elements);
          }
        });
      }
    }

    if (error.value) {
      status.filters = "error";
    }
  }

  function requestProducts(params: IRequestGoodsParams) {
    const { categoryIDs, storeCode, ...etc } = params;
    const props: IRequestGoodsAllParams = {
      storeCodes: [storeCode || storesStore.currentStore.code],
      onlyDiscount: false,
      includeForAdults: userStore.loadAdultContent,
      ...etc,
    };
    if (categoryIDs?.length && categoryIDs[0] !== 0) {
      const _categoryIDs = getCategoryDescendants(categoryIDs);
      props.categoryIDs = _categoryIDs.length ? _categoryIDs : categoryIDs;
    }
    return useTransport<{
      goods: IGoodsProduct[];
      pagination: IGoodsProducts["pagination"];
    }>(urls.goods.products, {
      method: "POST",
      body: props,
      watch: false,
    });
  }

  function requestProductsByIDs(params: IRequestGoodsByIDsParams) {
    const {
      goodIDs,
      shopType,
      storeCode = storesStore.currentStore.code,
    } = params;
    const props: IRequestGoodsByIDsParams = {
      goodIDs,
      shopType,
      storeCode,
    };
    return useTransport<{
      goods: IGoodsProductByIDs[];
      total: number;
    }>(urls.goods.productsByIDs, {
      method: "POST",
      body: props,
      watch: false,
    });
  }

  async function requestCategoryProducts(
    params: IRequestGoodsParams,
    additionally = false,
  ) {
    status.products = "pending";

    if (selectedFilters.value.length) {
      params.filters = [...selectedFilters.value];
    }

    const { data, error } = await requestProducts(params);

    if (data.value) {
      if (!data.value.goods.length) {
        status.products = "error";
        const message = "Wrong response with to much count of goods";
        if (process.client) {
          throw showError({
            fatal: true,
            statusMessage: message,
            statusCode: 404,
          });
        } else {
          throw createError({
            statusCode: 404,
            message,
            fatal: true,
          });
        }
      }

      status.products = "success";

      if (additionally) {
        products.items.push(...data.value.goods);
      } else {
        products.items = data.value.goods;
      }
      products.pagination = data.value.pagination;
    }

    if (error.value) {
      status.products = "error";

      if (
        error.value.statusCode &&
        error.value.statusCode >= HttpCodes.Error4xx &&
        error.value.statusCode < HttpCodes.Error5xx
      ) {
        throw createError({
          statusCode: 404,
          message: "Wrong response of goods",
          fatal: true,
        });
      }
    }
  }

  async function requestFilteredProducts(category?: number, submit?: boolean) {
    status.filteredProducts = "pending";

    const params: Partial<IRequestGoodsAllParams> = {
      storeCodes: [storesStore.currentStore.code],
      onlyDiscount: false,
      includeForAdults: userStore.loadAdultContent,
    };

    if (route.query.sortBy) {
      params.sortBy = route.query.sortBy as string;
    }

    if (route.query.order) {
      params.order = route.query.order as string;
    }

    if (category || category === 0) {
      params.categoryIDs = getCategoryDescendants([category]);
      selectedCategoryId.value = category;
      await requestFilters({ ...params });
      selectedFilters.value = [];
    } else if (selectedCategoryId.value) {
      params.categoryIDs = getCategoryDescendants([selectedCategoryId.value]);
    } else if (currentCategoryId.value) {
      params.categoryIDs = [currentCategoryId.value];
    }

    if (selectedFilters.value.length) {
      params.filters = [...selectedFilters.value];
    }

    const { data, error } = await requestProducts({
      ...params,
      pagination: {
        number: 1,
        size: GOODS_PRODUCTS_LIMIT,
      },
    });

    if (data.value) {
      filteredProducts.items = data.value.goods;
      filteredProducts.pagination = data.value.pagination;
      status.filteredProducts = "success";
      if (submit) {
        await submitFilteredGoods();
      }
    }

    if (error.value) {
      status.filteredProducts = "error";
    }
  }

  function rangeFilterChanged(
    filterId: IGoodsFilterId,
    rangeValue?: IRangeFilterValue,
  ) {
    const selectedFilter = selectedFilters.value.find(
      (filter) => filter.id === filterId,
    ) as IGoodsRangeRequestFilter;
    const selectedFilterValue =
      rangeValue || (selectedFilter && selectedFilter.range);
    return (
      selectedFilterValue &&
      filters.value.some((filter) => {
        const rangeFilter = filter as IGoodsRangeFilter;
        return (
          rangeFilter.id === filterId &&
          (rangeFilter.minvalue !== selectedFilterValue.min ||
            rangeFilter.maxvalue !== selectedFilterValue.max)
        );
      })
    );
  }

  async function updateFilters(
    filterId: IGoodsFilterId,
    value: IFilterValuesList | IRangeFilterValue,
    withSort?: boolean,
    submit?: boolean,
    url?: string,
  ) {
    const updatedIndex = selectedFilters.value.findIndex(
      (filter) =>
        filter.id === filterId && ("elements" in filter || "range" in filter),
    );
    if (Array.isArray(value)) {
      if (updatedIndex > -1) {
        if (value.length) {
          const typedElements = selectedFilters.value[
            updatedIndex
          ] as IGoodsGridRequestFilter;
          typedElements.elements = [...value];
        } else {
          selectedFilters.value.splice(updatedIndex, 1);
        }
      } else if (value.length) {
        selectedFilters.value.push({ id: filterId, elements: [...value] });
      }
    } else {
      const rangeValue = value as IRangeFilterValue;
      const isRangeFilterChanged = rangeFilterChanged(filterId, rangeValue);
      if (updatedIndex > -1) {
        if (isRangeFilterChanged) {
          const typedElements = selectedFilters.value[
            updatedIndex
          ] as IGoodsRangeRequestFilter;
          typedElements.range = { min: value.min, max: value.max };
        } else {
          selectedFilters.value.splice(updatedIndex, 1);
        }
      } else if (isRangeFilterChanged) {
        selectedFilters.value.push({ id: filterId, range: { ...value } });
      }
    }

    await requestFilteredProducts();
    if (submit && url && filteredProducts.pagination.totalCount) {
      await submitFilteredGoods(url);
    }
    if (withSort) {
      sortGridFilterBySelection(
        filterId,
        getSelectedGridFilterValuesById(filterId),
      );
    }
  }

  function getSelectedGridFilterValuesById(id: IGoodsFilterId) {
    return (
      (
        selectedFilters.value.find(
          (filter) => filter.id === id && "elements" in filter,
        ) as IGoodsGridRequestFilter
      )?.elements || ([] as IFilterValuesList)
    );
  }

  function getSelectedRangeFilterValueById(
    id: IGoodsFilterId,
  ): IRangeFilterValue {
    return (
      selectedFilters.value.find(
        (filter) => filter.id === id && "range" in filter,
      ) as IGoodsRangeRequestFilter
    )?.range as IRangeFilterValue;
  }

  async function submitFilteredGoods(url?: string) {
    const urlQueries = route.query ? { ...route.query } : {};
    delete urlQueries.page;
    const urlFilters = Object.keys(urlQueries).filter(
      (key) => key.includes("filter_") || key.includes("range_"),
    );
    for (const key of urlFilters) {
      delete urlQueries[key];
    }
    selectedFilters.value.forEach((filter) => {
      if ("elements" in filter) {
        const typedFilter = filter as IGoodsGridRequestFilter;
        if (
          typedFilter &&
          typedFilter.id &&
          typedFilter.elements &&
          typedFilter.elements.length
        ) {
          urlQueries[`filter_${typedFilter.id}`] = encodeURI(
            typedFilter.elements.join(","),
          );
        }
      }
      if ("range" in filter) {
        const typedFilter = filter as IGoodsRangeRequestFilter;
        if (typedFilter && typedFilter.id && typedFilter.range) {
          urlQueries[`range_${typedFilter.id}`] = encodeURI(
            `${typedFilter.range.min},${typedFilter.range.max}`,
          );
        }
      }
    });
    await router.push({
      path: url,
      query: {
        ...urlQueries,
      },
      hash: "#products",
    });
    await nextTick(() => {
      resetFilteredGoods();
      selectedCategoryId.value = null;
    });
  }

  function resetFilteredGoods() {
    filteredProducts.items = GOODS_DEFAULT_PRODUCTS.items;
    filteredProducts.pagination = GOODS_DEFAULT_PRODUCTS.pagination;
    status.filteredProducts = "initial";
  }

  function resetFilters() {
    status.filters = "initial";
    sortedFilters.value = [];
    filters.value = [];
  }

  function updateFiltersFromRoute(routeQuery: LocationQuery) {
    const routeKeys = Object.keys(routeQuery);
    const routeGridFilters = [
      ...routeKeys
        .filter((item) => item.includes("filter_"))
        .map((filter) => {
          return {
            id: filter.split("_")[1],
            elements: routeQuery[filter]
              ? decodeURI(routeQuery[filter] as string).split(",")
              : [],
          };
        }),
    ] as IGoodsGridRequestFilter[];
    const routeRangeFilters = [
      ...routeKeys
        .filter((item) => item.includes("range_"))
        .map((filter) => {
          const filterValue = routeQuery[filter]
            ? decodeURI(routeQuery[filter] as string).split(",")
            : [];
          return {
            id: filter.split("_")[1],
            range: {
              min: Number(filterValue[0]),
              max: Number(filterValue[1]),
            },
          };
        }),
    ] as IGoodsRangeRequestFilter[];
    selectedFilters.value = [
      ...cloneDeep(routeGridFilters),
      ...cloneDeep(routeRangeFilters),
    ];
    urlFilters.value = [...cloneDeep(selectedFilters.value)];
  }

  async function clearAllFilters() {
    selectedFilters.value = [];
    await requestFilteredProducts();
    submitFilteredGoods();
    nextTick(() => {
      resetFilteredGoods();
      selectedCategoryId.value = null;
    });
  }

  function sortGridFilterBySelection(
    id: IGoodsFilterId,
    selected?: IFilterValuesList,
  ) {
    if (!selected) {
      selected = getSelectedGridFilterValuesById(id);
    }
    if (!selected?.length) {
      return;
    }
    const filterValuesIndex = filters.value.findIndex(
      (filter) => filter.id === id && "elements" in filter,
    );
    if (filterValuesIndex > -1) {
      const typedFilter = filters.value[filterValuesIndex] as IGoodsGridFilter;
      const filterValues = typedFilter.elements;
      if (filterValues) {
        filterValues.sort((a, b) => {
          return (
            Number(selected?.includes(b.name)) -
            Number(selected?.includes(a.name))
          );
        });
        filters.value[filterValuesIndex] = {
          ...typedFilter,
          elements: filterValues,
        };
      }
    }
  }

  function clearCategoryProducts() {
    products.items = GOODS_DEFAULT_PRODUCTS.items;
    products.pagination = GOODS_DEFAULT_PRODUCTS.pagination;
    status.products = "initial";
    resetFilteredGoods();
    resetFilters();
  }

  function getLinkGoodsProduct(
    product: IGoodsProduct,
    categoryId?: number | string,
  ) {
    return `${Routes.Product}/${product.id}-${product.code}?shopCode=${storesStore.currentStore.code}&category=${categoryId}`;
  }

  return {
    requestCategoryProducts,
    requestFilteredProducts,
    requestFilters,
    clearCategoryProducts,
    clearAllFilters,
    filteredProducts,
    products,
    sortedFilters,
    filters,
    updateFiltersFromRoute,
    updateFilters,
    getSelectedGridFilterValuesById,
    getSelectedRangeFilterValueById,
    sortGridFilterBySelection,
    selectedFilters,
    urlFilters,
    rangeFilterChanged,
    submitFilteredGoods,
    currentCategoryId,
    selectedCategoryId,
    requestProducts,
    requestProductsByIDs,
    status,
    getLinkGoodsProduct,
  };
});

export function formatGoodsProduct(product: IGoodsProduct): IProduct {
  return {
    id: product.id,
    title: product.name,
    image: `${product.image.prefixUrl}${product.image.defaultSize}${product.image.postfixUrl}`,
    price: product.offers[0]?.price,
    oldPrice: product.offers[0]?.oldPrice,
    salePercent: product.offers[0]?.discountPercent
      ? `-${product.offers[0].discountPercent}%`
      : undefined,
    unit: product.unitValue,
    adult: product.isForAdults,
  };
}
