export const GOODS_PRODUCTS_LIMIT = 36;
export const GOODS_PRODUCTS_LIMIT_PAGE = GOODS_PRODUCTS_LIMIT - 1;
export const GOODS_BANNER_POSITION_INDEX = 4;
export const GOODS_DEFAULT_PRODUCTS = {
  items: [],
  pagination: {
    hasMore: false,
    size: 0,
    totalCount: 0,
    totalPages: 0,
    page: 0,
  },
};
